
export default {
  name: 'SrcsetImage',
  props: {
    unlazyImage: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fixUrls(urls) {
      return urls.replace(
        process.env.NUXT_ENV_WORDPRESS_BASE_URL,
        process.env.NUXT_ENV_BASE_URL
      )
    },
  },
}
